// header logic
// ascii blocks should use `display` to control visibility b/c it can counter
// the HTML `hidden` attribute
// https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/hidden
// https://developer.mozilla.org/en-US/docs/Web/CSS/visibility
header {
  #cowsay, #figlet {
    margin: 0 auto;
    overflow: visible;
  }
  #figlet {
    width: 65ch;
    display: block;
  }
  #cowsay {
    width: 29ch;
    height: 0;
    display: none;
  }
}

// Collapse header when on smaller displays
@media (max-width: calc(66ch + 48px)) {
  header {
    margin-left: 0;
    #figlet {
      height: 0;
      display: none;
    }
    #cowsay {
      height: auto;
      display: block;
    }
  }
}
@media (max-width: 30ch) {
  header {
    margin-left: 0;
    #cowsay {
      height: 0;
      visibility: hidden;
    }
    display: none;  // remove header completely
  }
  body {
    grid-template-rows: 1fr auto;  // reset grid template w/o header
  }
}

ul {
  padding-left: 2ch;
  list-style-type: '- ';
  // list-style-position: inside;
  li {
    p {
      display: inline;
    }
  }
}

#intro-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

img#portrait {
    width: 256px; /* TODO: decide if snapping to 1x, 2x, 4x is useful */
    image-rendering: pixelated;
    shape-outside: circle(50%);
    shape-margin: 1ch;
    margin: 1rem;
    margin-top: unset; /* ugly, but matches main margin-top */
}

section#intro {
    flex-grow: 1;
    flex-basis: 50%;
}
